.switch-yellow-background {
  background-color: yellow;
}

.tooltip-text {
  width: 16px;
  margin: 0px 5px;
  cursor: pointer;
}

.archive-svg {
  &:hover {
    svg {
      path {
        fill: #FFF;
      }
    }
  }
}

.green-slot-text {
  color: #3BB324 !important;
}

.switch-red-background {
  background-color: red;
}

.job-cell-container {
  margin: 30px auto;
  width: 900px;
  position: relative;
  background-color: #FFFFFF;
  // border: 1px solid rgba(17, 22, 26, 0.16);
  // box-shadow: 0 4px 3px 0 rgba(17, 22, 26, 0.16);
  @media (max-width: 768px) {
    width: 350px;
    height: auto;
  }
  .job-cell-header {
    width: 100%;
    height: 100px;
    background-color: #007AFF;
    display: flex;
    @media (max-width: 768px) {
      height: auto !important;
      display: block;
    }
    .header-right {
      width: 250px;
      display: flex;
      justify-content: center;
      height: 80px;
      align-items: center;
      @media (max-width: 768px) {
        // width: 100px;
        justify-content: flex-start;
      }
      .switch-inner-container {
        @media (max-width: 768px) {
          display: flex;
          justify-content: flex-start;
          margin: 10px;
        }
        .switch-inner {
          display: flex;
          justify-content: center;
          align-items: center;
        }
        .text {
          color: #FFFFFF;
          font-size: 14px;
          font-weight: 700;
          letter-spacing: 0.52px;
          // line-height: 14px;
          text-align: center;
          @media (max-width: 768px) {
            padding-left: 10px;
          }
        }
      }    }
    .header-left {
      width: 650px;
      margin-left: 10px;
      text-align: left;
      color: #FFFFFF;
      @media (max-width: 768px) {
        width: 350px;
      }
      .job-name {
        font-size: 17px;
        height: 40px;
        line-height: 40px;
        @media (max-width: 768px) {
          line-height: 20px;
          font-size: 17px;
          height: 60px;
          padding: 10px 0px;
          padding-right: 10px;
          font-weight: bold;
        }
      }
      .blank-line-for-mobile {
        height: 0;
        @media (max-width: 768px) {
          height: 20px;
        }
      }
      .interviewer-name {
        font-size: 14px;
        height: 30px;
        display: flex;
        @media (max-width: 768px) {
          height: 50px;
          display: block;
          font-size: 15px;
        }
        .location-id {
          text-align: left;
          @media (max-width: 768px) {
            margin-left: -14px;
          }
        }
        // line-height: 30px;
      }
      @media (max-width: 768px) {
        // // width: 250px;
        // display: block;
      }
    }
  }

  .cell-header-archived {
    background-color: #9B9B9B;
  }

  .job-details-container {
    display: flex;
    border: 1px solid #007AFF;
    @media (max-width: 768px) {
      display: block !important;
    }
    .job-images-container {
      width: 650px;
      display: flex;
      justify-content: center;
      align-items: center;
      input {
        display: none;
      }
      label {
        margin-bottom: 0px !important;
      }
      @media (max-width: 768px) {
        width: 350px;
        // min-height: 375px;
        margin-top: 10px;
        margin-left: 10px;
        flex-wrap: wrap;
        justify-content: flex-start;
        align-items: flex-start;
      }
      .image-upload {
        @media (max-width: 768px) {
          width: 110px;
        }
        label {
          @media (max-width: 768px) {
            width: 100px;
          }
        }
      }
      .already-added-image-container {
        width: 110px;
        height: 100px;
        margin: 10px;
        display: flex;
        justify-content: center;
        align-items: center;
        border: 1px solid #263242;
        border-radius: 10px;
        position: relative;
        margin-top: 0px;
        @media (max-width: 768px) {
          width: 100px;
          margin: 0px 5px;
          height: 95px;
        }
        .close-icon {
          position: absolute;
          top: 5px;
          height: 20px;
          width: 20px;
          right: 5px;
          cursor: pointer;
          img {
            height: 15px;
            width: 15px;
          }
        }
        .already-added-image {
          width: 75px;
          height: 75px;
          object-fit: cover;
        }
      }
      .single-job-image {
        margin: 10px;
        width: 110px;
        height: 100px;
        background-size: contain;
        cursor: pointer;
        background-repeat: no-repeat;
        background-image: url('../../../images/image-upload-icon-normal.png');
        @media (max-width: 768px) {
          width: 100px;
          margin: 0px;
          height: 110px;
          // margin-top: 5px;
        }
        &:hover {
          background-image: url('../../../images/image-upload-icon-hover.png');
        }
        &-disabled {
          @extend .single-job-image;
          cursor: default;
          &:hover {
            background-image: url('../../../images/image-upload-icon-normal.png');
          }
        }
        // height: 125px;
      }
    }

    .job-images-container-archived {
      filter: grayscale(1);
    }
    .job-action-container {
      width: 250px;
      justify-content: center;
      align-items: center;
      // margin: 0px auto;
      @media (max-width: 768px) {
        width: 350px;
        // display: block;
        // display: flex;
      }
      button {
        &:not(.btn-disabled) {
          border: 1px solid #007AFF;
          color: #007AFF;
        }
        margin: 10px auto;
        
        border-radius: 20px;
        background-color: #FFFFFF;
        @media (max-width: 768px) {
          min-width: 90% !important;
          font-size: 16px;
        }
        &:hover {
          &:not(.btn-disabled) {
            background-color: #007AFF !important;
            color: #FFFFFF !important;
          }
        }
      }
    }
  }

  .job-details-container-archived {
    border: 1px solid #9B9B9B;
  }

  .special-add-slots-container {
    position: absolute;
    width: 100%;
    top: 100px;
    bottom: 0px;
    height: 165px;
    background: #FE7374;
    // opacity: 0.8;
    z-index: 9;
    @media (max-width: 768px) {
      height: 405px;
      top: 240px;
    }
    .special-button-container {
      .single-button {
        border: 1px solid #FFFFFF;
        border-radius: 25px;
        width: 200px;
        height: 40px;
        background-color: transparent;
        color: #FFFFFF;
        width: 140px !important;
        font-size: 13px;
      }
      .secondary-button {
        // background-color: #FFFFFF;
        // border: 1px solid #007AFF;
        // color: #007AFF;
      }
    }
    .text {
      color: #FFFFFF;
      text-align: center;
      margin: 30px auto 20px;
      @media (max-width: 768px) {
        margin-top: 40px;
      }
      .header {
        margin-bottom: 5px;
        font-size: 18px;
        display: flex;
        justify-content: center;
        align-items: center;
        .alert-image {
          height: 60px;
        }
        .view-edit-icon {
          color: yellow;
        }
      }
      .description {
        font-size: 12px;
      }
    }
    .job-button {
      margin: 0px auto;
    }
  }
  .inner-container {
    position: relative;
    width: 100%;
    // height: 10px;
    padding: 25px;
    .job-header-container {
      // height: 45px;
      .job-name {
        @media (max-width: 768px) {
          margin-bottom: 10px;
          width: 100%;
        }
      }
      .status {
        width: 200px;
        display: flex;
        justify-content: center;
        @media (max-width: 768px) {
          width: 100px;
        }
        .switch-inner-container {
          @media (max-width: 768px) {
            // display: none;
          }
          .switch-inner {
            display: flex;
            justify-content: center;
            align-items: center;
          }
          .text {
            color: #000000;

            font-size: 14px;
            font-weight: 700;
            letter-spacing: 0.52px;
            // line-height: 14px;
            text-align: center;
          }
        }
      }
    }
    .job-details {
      display: flex;
      @media (max-width: 768px) {
        display: block;
      }
      .job-action-buttons {
        @media (max-width: 768px) {
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-top: 20px;
        }
        .job-button {
          margin: 10px auto;
          @media (max-width: 768px) {
            margin: 0px;
            width: 60px !important;
            max-width: 80px;
            min-width: 80px !important;
          }
        }
      }
      .location-details {
        width: 500px;
        @media (max-width: 768px) {
          width: 300px;
          display: flex;
        }
        .details-container {
          @media (max-width: 768px) {
            width: 200px;
          }
          .inner {
            display: flex;
            @media (max-width: 768px) {
              font-size: 11px;
            }
            .label {
              width: 140px;
              text-align: left;
              @media (max-width: 768px) {
                width: 90px;
              }
            }
          }
        }
        .switch-inner-container-mobile {
          display: none;
          width: 100px;
          @media (max-width: 768px) {
            display: block;
          }
          .switch-inner {
            display: flex;
            justify-content: center;
            align-items: center;
          }
          .text {
            color: #000000;
            font-size: 14px;
            font-weight: 700;
            letter-spacing: 0.52px;
            // line-height: 14px;
            text-align: center;
            .header {
              margin-bottom: 15px;
            }
            .description {

            }
          }
        }
        .no-slots-text {
          margin-top: 50px;
          text-align: left !important;
          color: #9B9B9B;
          font-size: 12px;
          font-weight: 400;
          letter-spacing: 0.52px;
          line-height: 14px;
          @media (max-width: 768px) {
            display: none;
          }
          // margin: 10px auto;
        }
      }
    }
  }

  .job-header-container {
    color: #000000;
    padding: 5px;

    font-size: 18px;
    font-weight: 700;
    letter-spacing: 0.59px;
    line-height: 22px;
    // width: 529px;
    text-align: left;
    display: flex;
    justify-content: space-between;
    .header-text {
      width: 530px;
      @media (max-width: 768px) {
        width: 230px;
        font-size: 16px;
        min-width: 230px;
      }
    }
    .switch-container {
      width: 195px;
      display: flex;
      height: 45px;
      justify-content: center;
      @media (max-width: 768px) {
        width: 80px;
      }
      .switch-inner-container {
        .switch-inner {
          display: flex;
          justify-content: center;
          align-items: center;
        }
        .text {
          color: #000000;

          font-size: 14px;
          font-weight: 700;
          letter-spacing: 0.52px;
          // line-height: 14px;
          text-align: center;
        }
      }
    }
  }
  .job-details-button-container {
    display: flex;
    margin: 0px 10px;
    .tooltip-container {
      display: flex;
      align-items: center;
      .tooltip-text {
        height: 15px;
        width: 15px;
        font-size: 20px;
      }
    }
    .view-edit-icon {
      display: flex;
      align-items: center;
      color: #007AFF;
      cursor: pointer;
      font-size: 25px;
    }
    .copy-button {
      border: 1px solid #007AFF;
      color: #007AFF;
      width: 72px;
      cursor: pointer;
      height: 26px;
      @media (max-width: 768px) {
        display: none;
      }
    }
    .copy-button-responsive {
      display: none;
      @media (max-width: 768px) {
        width: 24px;
        height: 24px;
        background-color: #007AFF;
        color: #FFFFFF;
        display: flex;
        border-radius: 12px;
        justify-content: center;
        align-items: center;
      }
    }
  }
  .applications-guidance-text {
    color: #9B9B9B;
    font-size: 12px;
    font-weight: 400;
    letter-spacing: 0.52px;
    line-height: 14px;
    margin: 10px auto;
    text-align: center;
  }
  .interview-details {
    width: 750px;
    display: flex;
    box-sizing: border-box;
    justify-content: space-between;
    padding: 10px;
    @media (max-width: 768px) {
      width: 350px;
    }
    .location-details {
      width: 530px;
      @media (max-width: 768px) {
        width: 140px;
      }
      .label {
        color: #9B9B9B;
        font-size: 12px;
        font-weight: 400;
        line-height: 14px;
        text-align: left;
        margin-bottom: 5px;
      }
      .value {
        color: #4A4A4A;
        font-size: 14px;
        font-weight: 400;
        line-height: 17px;
        text-align: left;
        margin-bottom: 10px;
      }
    }
    .interview-times-details {
      display: flex;
      justify-content: center;
      .inner {
        .convinient-times-header {
          color: #9B9B9B;
          font-size: 12px;
          font-weight: 400;
          line-height: 14px;
          text-align: center;
          margin-bottom: 5px;
        }
        .convinient-times-value {
          color: #D0021B;
          font-size: 14px;
          font-weight: 700;
          line-height: 17px;
          text-align: center;
          margin-bottom: 5px;
          @media (max-width: 768px) {
            font-size: 11px;
          }
        }
      }
    }
  }
  .applications-container {
    height: auto;
  }
  .show-more-container {
    background-color: #FFFFFF;
    // height: 40px;
    position: absolute;
    bottom: -35px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    .show-more-button {
      background-color: #007AFF;
      border-radius: 15px;
      outline: none;
      cursor: pointer;
      padding: 5px 10px;
      border: 1px solid #007AFF;
      color: #FFFFFF;
    }
  }
}

.white-text {
  color: #FFF7F7;
}


.job-type-button {
  width: 8rem
}

.job-type-button-archived {
  filter: grayscale(1)
}

@media (max-width: 768px) {
  .job-type-button {
    width: 16rem
  }
}
