.arrows {
  opacity: 1 !important;
}

.preview-jobs-container {
  font-size: 14px;
  text-align: left;
  margin: 20px auto;
  padding: 10px;
}
.preview-jobs-container .redirect-button-container {
  display: flex;
  width: 375px;
  justify-content: space-between;
}
@media (max-width: 768px) {
  .preview-jobs-container .redirect-button-container {
    width: 330px;
  }
}
.preview-jobs-container .redirect-button-container button {
  background-color: #FFFFFF;
  border: 1px solid #007AFF;
  border-radius: 12px;
  min-width: 75px;
  height: 46px;
  color: #007AFF;
}
.preview-jobs-container .main-header {
  font-size: 21px;
  font-weight: bold;
  letter-spacing: 1.38px;
  line-height: 25px;
  width: 317px;
  text-align: left;
  margin: 15px 0 0;
}
.preview-jobs-container .inner-label {
  color: #000000;
  font-size: 15px;
  font-weight: bold;
  letter-spacing: 1.25px;
  line-height: 23px;
  width: 317px;
  text-align: left;
  margin: 15px 0 0;
}
.preview-jobs-container .value {
  color: #000000;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 1.05px;
  line-height: 19px;
  width: 317px;
  text-align: left;
  margin-bottom: 15px;
}
.preview-jobs-container .brand-name {
  width: 100%;
  text-align: left;
}
.preview-jobs-container .salary-time-container {
  width: 100%;
  border-top: 1px solid gray;
  height: 40px;
  display: flex;
  align-items: center;
  font-size: 10px;
}
.preview-jobs-container .salary-time-container .label {
  width: 50%;
  text-align: left;
  color: gray;
}
.preview-jobs-container .salary-time-container .value {
  width: 50%;
  text-align: left;
}
.preview-jobs-container .location-container {
  width: 100%;
  min-height: 40px;
  padding: 5px 0px;
  border-top: 1px solid gray;
  display: flex;
  align-items: center;
  font-size: 10px;
}
.preview-jobs-container .location-container .label {
  width: 50%;
  text-align: left;
  color: gray;
}
.preview-jobs-container .location-container .label .tooltip-text {
  width: 12px !important;
  margin-bottom: -2px;
}
.preview-jobs-container .location-container .value {
  width: 50%;
  text-align: left;
}
.preview-jobs-container .heading {
  width: 100%;
  margin: 20px 0px 10px;
  text-align: left;
}
.preview-jobs-container .sub-heading {
  width: 100%;
  text-align: left;
  margin: 20px 0px 10px;
  font-size: 14px;
}
.preview-jobs-container .requirement-value {
  width: 100%;
  text-align: left;
  height: 40px;
  border-top: 1px solid gray;
  display: flex;
  align-items: center;
  font-size: 10px;
}
.preview-jobs-container .work-images {
  width: 100%;
}
.preview-jobs-container .work-images .control-arrow {
  opacity: 1;
}
.preview-jobs-container .work-images img {
  width: 100%;
  height: auto;
}
.preview-jobs-container .merits-container .single-merit-container {
  border-top: 1px solid gray;
  width: 100%;
  min-height: 40px;
  font-size: 10px;
  display: flex;
  align-items: center;
}
.preview-jobs-container .merits-container .single-merit-container .value {
  display: block;
  text-align: left;
}
.preview-jobs-container .merits-container .single-merit-container .label {
  width: 50%;
  color: gray;
  text-align: left;
}
.preview-jobs-container .work-hours-container {
  font-size: 10px;
  text-align: left;
}
.preview-jobs-container .work-hours-container .single-time {
  display: flex;
  border-top: 1px solid gray;
}
.preview-jobs-container .work-hours-container .single-time .label {
  width: 30%;
  text-align: left;
}
.preview-jobs-container .work-hours-container .single-time .value {
  width: 70%;
  text-align: left;
  display: flex;
}
.preview-jobs-container .work-hours-container .single-time .value .single-day-container {
  flex: 1;
}
.preview-jobs-container .work-hours-container .single-time .value .single-day-container .day-name {
  text-align: center;
  color: gray;
}
.preview-jobs-container .work-hours-container .single-time .value .single-day-container .day-present {
  text-align: center;
}
.preview-jobs-container .training-container {
  width: 100%;
  height: 40px;
  font-size: 10px;
  display: flex;
  align-items: center;
  border-top: 1px solid gray;
}
.preview-jobs-container .training-container .label {
  width: 50%;
  color: gray;
  text-align: left;
}
.preview-jobs-container .training-container .value {
  width: 50%;
  text-align: left;
}

.redirect-button {
  background-color: #fff;
  border: 1px solid #007aff;
  border-radius: 12px;
  min-width: 75px;
  height: 46px;
  color: #007aff;
}

.redirect-button-disabled {
  background-color: #fff;
  border: 1px solid #707070;
  border-radius: 12px;
  min-width: 75px;
  height: 46px;
  color: #707070;
}

hr {
  margin: 8px 0;
  border: 1px solid #707070;
}

.header {
  color: #263242;
  font-size: 22px;
  font-weight: 500;
}

.key-text {
  color: #000000;
  font-size: 18px;
  font-weight: 500;
}

.mt-10 {
  margin-top: 10px;
}

.d-flex {
  display: flex !important;
}

.flex-auto {
  flex: auto !important;
  width: 75% !important;
}

.mt-20 {
  margin-top: 20px;
}

#job-detail-text {
  border: none !important;
  pointer-events: none !important;
  padding: 0 !important;
}