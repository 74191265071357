.switch-yellow-background {
  background-color: yellow;
}

.tooltip-text {
  width: 16px;
  margin: 0px 5px;
  cursor: pointer;
}

.archive-svg:hover svg path {
  fill: #FFF;
}

.green-slot-text {
  color: #3BB324 !important;
}

.switch-red-background {
  background-color: red;
}

.job-cell-container {
  margin: 30px auto;
  width: 900px;
  position: relative;
  background-color: #FFFFFF;
}
@media (max-width: 768px) {
  .job-cell-container {
    width: 350px;
    height: auto;
  }
}
.job-cell-container .job-cell-header {
  width: 100%;
  height: 100px;
  background-color: #007AFF;
  display: flex;
}
@media (max-width: 768px) {
  .job-cell-container .job-cell-header {
    height: auto !important;
    display: block;
  }
}
.job-cell-container .job-cell-header .header-right {
  width: 250px;
  display: flex;
  justify-content: center;
  height: 80px;
  align-items: center;
}
@media (max-width: 768px) {
  .job-cell-container .job-cell-header .header-right {
    justify-content: flex-start;
  }
}
@media (max-width: 768px) {
  .job-cell-container .job-cell-header .header-right .switch-inner-container {
    display: flex;
    justify-content: flex-start;
    margin: 10px;
  }
}
.job-cell-container .job-cell-header .header-right .switch-inner-container .switch-inner {
  display: flex;
  justify-content: center;
  align-items: center;
}
.job-cell-container .job-cell-header .header-right .switch-inner-container .text {
  color: #FFFFFF;
  font-size: 14px;
  font-weight: 700;
  letter-spacing: 0.52px;
  text-align: center;
}
@media (max-width: 768px) {
  .job-cell-container .job-cell-header .header-right .switch-inner-container .text {
    padding-left: 10px;
  }
}
.job-cell-container .job-cell-header .header-left {
  width: 650px;
  margin-left: 10px;
  text-align: left;
  color: #FFFFFF;
}
@media (max-width: 768px) {
  .job-cell-container .job-cell-header .header-left {
    width: 350px;
  }
}
.job-cell-container .job-cell-header .header-left .job-name {
  font-size: 17px;
  height: 40px;
  line-height: 40px;
}
@media (max-width: 768px) {
  .job-cell-container .job-cell-header .header-left .job-name {
    line-height: 20px;
    font-size: 17px;
    height: 60px;
    padding: 10px 0px;
    padding-right: 10px;
    font-weight: bold;
  }
}
.job-cell-container .job-cell-header .header-left .blank-line-for-mobile {
  height: 0;
}
@media (max-width: 768px) {
  .job-cell-container .job-cell-header .header-left .blank-line-for-mobile {
    height: 20px;
  }
}
.job-cell-container .job-cell-header .header-left .interviewer-name {
  font-size: 14px;
  height: 30px;
  display: flex;
}
@media (max-width: 768px) {
  .job-cell-container .job-cell-header .header-left .interviewer-name {
    height: 50px;
    display: block;
    font-size: 15px;
  }
}
.job-cell-container .job-cell-header .header-left .interviewer-name .location-id {
  text-align: left;
}
@media (max-width: 768px) {
  .job-cell-container .job-cell-header .header-left .interviewer-name .location-id {
    margin-left: -14px;
  }
}
.job-cell-container .cell-header-archived {
  background-color: #9B9B9B;
}
.job-cell-container .job-details-container {
  display: flex;
  border: 1px solid #007AFF;
}
@media (max-width: 768px) {
  .job-cell-container .job-details-container {
    display: block !important;
  }
}
.job-cell-container .job-details-container .job-images-container {
  width: 650px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.job-cell-container .job-details-container .job-images-container input {
  display: none;
}
.job-cell-container .job-details-container .job-images-container label {
  margin-bottom: 0px !important;
}
@media (max-width: 768px) {
  .job-cell-container .job-details-container .job-images-container {
    width: 350px;
    margin-top: 10px;
    margin-left: 10px;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: flex-start;
  }
}
@media (max-width: 768px) {
  .job-cell-container .job-details-container .job-images-container .image-upload {
    width: 110px;
  }
}
@media (max-width: 768px) {
  .job-cell-container .job-details-container .job-images-container .image-upload label {
    width: 100px;
  }
}
.job-cell-container .job-details-container .job-images-container .already-added-image-container {
  width: 110px;
  height: 100px;
  margin: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #263242;
  border-radius: 10px;
  position: relative;
  margin-top: 0px;
}
@media (max-width: 768px) {
  .job-cell-container .job-details-container .job-images-container .already-added-image-container {
    width: 100px;
    margin: 0px 5px;
    height: 95px;
  }
}
.job-cell-container .job-details-container .job-images-container .already-added-image-container .close-icon {
  position: absolute;
  top: 5px;
  height: 20px;
  width: 20px;
  right: 5px;
  cursor: pointer;
}
.job-cell-container .job-details-container .job-images-container .already-added-image-container .close-icon img {
  height: 15px;
  width: 15px;
}
.job-cell-container .job-details-container .job-images-container .already-added-image-container .already-added-image {
  width: 75px;
  height: 75px;
  object-fit: cover;
}
.job-cell-container .job-details-container .job-images-container .single-job-image, .job-cell-container .job-details-container .job-images-container .single-job-image-disabled {
  margin: 10px;
  width: 110px;
  height: 100px;
  background-size: contain;
  cursor: pointer;
  background-repeat: no-repeat;
  background-image: url("../../../images/image-upload-icon-normal.png");
}
@media (max-width: 768px) {
  .job-cell-container .job-details-container .job-images-container .single-job-image, .job-cell-container .job-details-container .job-images-container .single-job-image-disabled {
    width: 100px;
    margin: 0px;
    height: 110px;
  }
}
.job-cell-container .job-details-container .job-images-container .single-job-image:hover, .job-cell-container .job-details-container .job-images-container .single-job-image-disabled:hover {
  background-image: url("../../../images/image-upload-icon-hover.png");
}
.job-cell-container .job-details-container .job-images-container .single-job-image-disabled {
  cursor: default;
}
.job-cell-container .job-details-container .job-images-container .single-job-image-disabled:hover {
  background-image: url("../../../images/image-upload-icon-normal.png");
}
.job-cell-container .job-details-container .job-images-container-archived {
  filter: grayscale(1);
}
.job-cell-container .job-details-container .job-action-container {
  width: 250px;
  justify-content: center;
  align-items: center;
}
@media (max-width: 768px) {
  .job-cell-container .job-details-container .job-action-container {
    width: 350px;
  }
}
.job-cell-container .job-details-container .job-action-container button {
  margin: 10px auto;
  border-radius: 20px;
  background-color: #FFFFFF;
}
.job-cell-container .job-details-container .job-action-container button:not(.btn-disabled) {
  border: 1px solid #007AFF;
  color: #007AFF;
}
@media (max-width: 768px) {
  .job-cell-container .job-details-container .job-action-container button {
    min-width: 90% !important;
    font-size: 16px;
  }
}
.job-cell-container .job-details-container .job-action-container button:hover:not(.btn-disabled) {
  background-color: #007AFF !important;
  color: #FFFFFF !important;
}
.job-cell-container .job-details-container-archived {
  border: 1px solid #9B9B9B;
}
.job-cell-container .special-add-slots-container {
  position: absolute;
  width: 100%;
  top: 100px;
  bottom: 0px;
  height: 165px;
  background: #FE7374;
  z-index: 9;
}
@media (max-width: 768px) {
  .job-cell-container .special-add-slots-container {
    height: 405px;
    top: 240px;
  }
}
.job-cell-container .special-add-slots-container .special-button-container .single-button {
  border: 1px solid #FFFFFF;
  border-radius: 25px;
  width: 200px;
  height: 40px;
  background-color: transparent;
  color: #FFFFFF;
  width: 140px !important;
  font-size: 13px;
}
.job-cell-container .special-add-slots-container .text {
  color: #FFFFFF;
  text-align: center;
  margin: 30px auto 20px;
}
@media (max-width: 768px) {
  .job-cell-container .special-add-slots-container .text {
    margin-top: 40px;
  }
}
.job-cell-container .special-add-slots-container .text .header {
  margin-bottom: 5px;
  font-size: 18px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.job-cell-container .special-add-slots-container .text .header .alert-image {
  height: 60px;
}
.job-cell-container .special-add-slots-container .text .header .view-edit-icon {
  color: yellow;
}
.job-cell-container .special-add-slots-container .text .description {
  font-size: 12px;
}
.job-cell-container .special-add-slots-container .job-button {
  margin: 0px auto;
}
.job-cell-container .inner-container {
  position: relative;
  width: 100%;
  padding: 25px;
}
@media (max-width: 768px) {
  .job-cell-container .inner-container .job-header-container .job-name {
    margin-bottom: 10px;
    width: 100%;
  }
}
.job-cell-container .inner-container .job-header-container .status {
  width: 200px;
  display: flex;
  justify-content: center;
}
@media (max-width: 768px) {
  .job-cell-container .inner-container .job-header-container .status {
    width: 100px;
  }
}
.job-cell-container .inner-container .job-header-container .status .switch-inner-container .switch-inner {
  display: flex;
  justify-content: center;
  align-items: center;
}
.job-cell-container .inner-container .job-header-container .status .switch-inner-container .text {
  color: #000000;
  font-size: 14px;
  font-weight: 700;
  letter-spacing: 0.52px;
  text-align: center;
}
.job-cell-container .inner-container .job-details {
  display: flex;
}
@media (max-width: 768px) {
  .job-cell-container .inner-container .job-details {
    display: block;
  }
}
@media (max-width: 768px) {
  .job-cell-container .inner-container .job-details .job-action-buttons {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 20px;
  }
}
.job-cell-container .inner-container .job-details .job-action-buttons .job-button {
  margin: 10px auto;
}
@media (max-width: 768px) {
  .job-cell-container .inner-container .job-details .job-action-buttons .job-button {
    margin: 0px;
    width: 60px !important;
    max-width: 80px;
    min-width: 80px !important;
  }
}
.job-cell-container .inner-container .job-details .location-details {
  width: 500px;
}
@media (max-width: 768px) {
  .job-cell-container .inner-container .job-details .location-details {
    width: 300px;
    display: flex;
  }
}
@media (max-width: 768px) {
  .job-cell-container .inner-container .job-details .location-details .details-container {
    width: 200px;
  }
}
.job-cell-container .inner-container .job-details .location-details .details-container .inner {
  display: flex;
}
@media (max-width: 768px) {
  .job-cell-container .inner-container .job-details .location-details .details-container .inner {
    font-size: 11px;
  }
}
.job-cell-container .inner-container .job-details .location-details .details-container .inner .label {
  width: 140px;
  text-align: left;
}
@media (max-width: 768px) {
  .job-cell-container .inner-container .job-details .location-details .details-container .inner .label {
    width: 90px;
  }
}
.job-cell-container .inner-container .job-details .location-details .switch-inner-container-mobile {
  display: none;
  width: 100px;
}
@media (max-width: 768px) {
  .job-cell-container .inner-container .job-details .location-details .switch-inner-container-mobile {
    display: block;
  }
}
.job-cell-container .inner-container .job-details .location-details .switch-inner-container-mobile .switch-inner {
  display: flex;
  justify-content: center;
  align-items: center;
}
.job-cell-container .inner-container .job-details .location-details .switch-inner-container-mobile .text {
  color: #000000;
  font-size: 14px;
  font-weight: 700;
  letter-spacing: 0.52px;
  text-align: center;
}
.job-cell-container .inner-container .job-details .location-details .switch-inner-container-mobile .text .header {
  margin-bottom: 15px;
}
.job-cell-container .inner-container .job-details .location-details .no-slots-text {
  margin-top: 50px;
  text-align: left !important;
  color: #9B9B9B;
  font-size: 12px;
  font-weight: 400;
  letter-spacing: 0.52px;
  line-height: 14px;
}
@media (max-width: 768px) {
  .job-cell-container .inner-container .job-details .location-details .no-slots-text {
    display: none;
  }
}
.job-cell-container .job-header-container {
  color: #000000;
  padding: 5px;
  font-size: 18px;
  font-weight: 700;
  letter-spacing: 0.59px;
  line-height: 22px;
  text-align: left;
  display: flex;
  justify-content: space-between;
}
.job-cell-container .job-header-container .header-text {
  width: 530px;
}
@media (max-width: 768px) {
  .job-cell-container .job-header-container .header-text {
    width: 230px;
    font-size: 16px;
    min-width: 230px;
  }
}
.job-cell-container .job-header-container .switch-container {
  width: 195px;
  display: flex;
  height: 45px;
  justify-content: center;
}
@media (max-width: 768px) {
  .job-cell-container .job-header-container .switch-container {
    width: 80px;
  }
}
.job-cell-container .job-header-container .switch-container .switch-inner-container .switch-inner {
  display: flex;
  justify-content: center;
  align-items: center;
}
.job-cell-container .job-header-container .switch-container .switch-inner-container .text {
  color: #000000;
  font-size: 14px;
  font-weight: 700;
  letter-spacing: 0.52px;
  text-align: center;
}
.job-cell-container .job-details-button-container {
  display: flex;
  margin: 0px 10px;
}
.job-cell-container .job-details-button-container .tooltip-container {
  display: flex;
  align-items: center;
}
.job-cell-container .job-details-button-container .tooltip-container .tooltip-text {
  height: 15px;
  width: 15px;
  font-size: 20px;
}
.job-cell-container .job-details-button-container .view-edit-icon {
  display: flex;
  align-items: center;
  color: #007AFF;
  cursor: pointer;
  font-size: 25px;
}
.job-cell-container .job-details-button-container .copy-button {
  border: 1px solid #007AFF;
  color: #007AFF;
  width: 72px;
  cursor: pointer;
  height: 26px;
}
@media (max-width: 768px) {
  .job-cell-container .job-details-button-container .copy-button {
    display: none;
  }
}
.job-cell-container .job-details-button-container .copy-button-responsive {
  display: none;
}
@media (max-width: 768px) {
  .job-cell-container .job-details-button-container .copy-button-responsive {
    width: 24px;
    height: 24px;
    background-color: #007AFF;
    color: #FFFFFF;
    display: flex;
    border-radius: 12px;
    justify-content: center;
    align-items: center;
  }
}
.job-cell-container .applications-guidance-text {
  color: #9B9B9B;
  font-size: 12px;
  font-weight: 400;
  letter-spacing: 0.52px;
  line-height: 14px;
  margin: 10px auto;
  text-align: center;
}
.job-cell-container .interview-details {
  width: 750px;
  display: flex;
  box-sizing: border-box;
  justify-content: space-between;
  padding: 10px;
}
@media (max-width: 768px) {
  .job-cell-container .interview-details {
    width: 350px;
  }
}
.job-cell-container .interview-details .location-details {
  width: 530px;
}
@media (max-width: 768px) {
  .job-cell-container .interview-details .location-details {
    width: 140px;
  }
}
.job-cell-container .interview-details .location-details .label {
  color: #9B9B9B;
  font-size: 12px;
  font-weight: 400;
  line-height: 14px;
  text-align: left;
  margin-bottom: 5px;
}
.job-cell-container .interview-details .location-details .value {
  color: #4A4A4A;
  font-size: 14px;
  font-weight: 400;
  line-height: 17px;
  text-align: left;
  margin-bottom: 10px;
}
.job-cell-container .interview-details .interview-times-details {
  display: flex;
  justify-content: center;
}
.job-cell-container .interview-details .interview-times-details .inner .convinient-times-header {
  color: #9B9B9B;
  font-size: 12px;
  font-weight: 400;
  line-height: 14px;
  text-align: center;
  margin-bottom: 5px;
}
.job-cell-container .interview-details .interview-times-details .inner .convinient-times-value {
  color: #D0021B;
  font-size: 14px;
  font-weight: 700;
  line-height: 17px;
  text-align: center;
  margin-bottom: 5px;
}
@media (max-width: 768px) {
  .job-cell-container .interview-details .interview-times-details .inner .convinient-times-value {
    font-size: 11px;
  }
}
.job-cell-container .applications-container {
  height: auto;
}
.job-cell-container .show-more-container {
  background-color: #FFFFFF;
  position: absolute;
  bottom: -35px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.job-cell-container .show-more-container .show-more-button {
  background-color: #007AFF;
  border-radius: 15px;
  outline: none;
  cursor: pointer;
  padding: 5px 10px;
  border: 1px solid #007AFF;
  color: #FFFFFF;
}

.white-text {
  color: #FFF7F7;
}

.job-type-button {
  width: 8rem;
}

.job-type-button-archived {
  filter: grayscale(1);
}

@media (max-width: 768px) {
  .job-type-button {
    width: 16rem;
  }
}