.arrows {
  opacity: 1 !important;
}
.preview-jobs-container {
  font-size: 14px;
  text-align: left;
  // border: 4px solid #007AFF;
  margin: 20px auto;
  padding: 10px;
  .redirect-button-container {
    display: flex;
    width: 375px;
    justify-content: space-between;
    @media (max-width: 768px) {
      width: 330px;
    }
    button {
      background-color: #FFFFFF;
      border: 1px solid #007AFF;
      border-radius: 12px;
      min-width: 75px;
      height: 46px;
      color: #007AFF;
    }
  }
  .main-header {
    font-size: 21px;
    font-weight: bold;
    //font-weight: 400;
    letter-spacing: 1.38px;
    line-height: 25px;
    width: 317px;
    text-align: left;
    margin: 15px 0 0;
  }
  .inner-label {
    color: #000000;
    font-size: 15px;
    font-weight: bold;
    letter-spacing: 1.25px;
    line-height: 23px;
    width: 317px;
    text-align: left;
    margin: 15px 0 0;
  }
  .value {
    color: #000000;
    font-size: 16px;
    font-weight: 400;
    letter-spacing: 1.05px;
    line-height: 19px;
    width: 317px;
    text-align: left;
    margin-bottom: 15px;
  }
  .brand-name {
    width: 100%;
    // height: 40px;
    text-align: left;
  }
  .salary-time-container {
    width: 100%;
    border-top: 1px solid gray;
    // border-bottom: 1px solid gray;
    height: 40px;
    display: flex;
    align-items: center;
    font-size: 10px;
    .label {
      width: 50%;
      text-align: left;
      color: gray;
    }
    .value {
      width: 50%;
      text-align: left;
    }
  }
  .location-container {
    width: 100%;
    min-height: 40px;
    padding: 5px 0px;
    border-top: 1px solid gray;
    display: flex;
    align-items: center;
    font-size: 10px;
    .label {
      width: 50%;
      text-align: left;
      color: gray;
      .tooltip-text {
        width: 12px !important;
        margin-bottom: -2px;
      }
    }
    .value {
      width: 50%;
      text-align: left;
    }
  }
  .heading {
    width: 100%;
    margin: 20px 0px 10px;
    text-align: left;
  }
  .sub-heading {
    width: 100%;
    text-align: left;
    margin: 20px 0px 10px;
    font-size: 14px;
  }
  .requirement-value {
    width: 100%;
    text-align: left;
    height: 40px;
    border-top: 1px solid gray;
    display: flex;
    align-items: center;
    font-size: 10px;
  }
  .work-images {
    width: 100%;
    .control-arrow {
      opacity: 1;
    }
    img {
      width: 100%;
      height: auto;
    }
  }
  .merits-container {
    .single-merit-container {
      border-top: 1px solid gray;
      width: 100%;
      min-height: 40px;
      font-size: 10px;
      display: flex;
      align-items: center;
      .value {
        display: block;
        text-align: left;
      }
      .label {
        width: 50%;
        color: gray;
        text-align: left;
      }
    }
  }
  .work-hours-container {
    font-size: 10px;
    text-align: left;
    .single-time {
      display: flex;
      border-top: 1px solid gray;
      .label {
        // color: gray;
        width: 30%;
        text-align: left;
      }
      .value {
        width: 70%;
        text-align: left;
        display: flex;
        .single-day-container {
          flex: 1;
          .day-name {
            text-align: center;
            color: gray;
          }
          .day-present {
            text-align: center;
          }
        }

      }
    }
  }
  .training-container {
    width: 100%;
    height: 40px;
    font-size: 10px;
    display: flex;
    align-items: center;
    border-top: 1px solid gray;
    .label {
      width: 50%;
      color: gray;
      text-align: left;
    }
    .value {
      width: 50%;
      text-align: left;
    }
  }
}

.redirect-button {
  background-color: #fff;
  border: 1px solid #007aff;
  border-radius: 12px;
  min-width: 75px;
  height: 46px;
  color: #007aff;
}

.redirect-button-disabled {
  background-color: #fff;
  border: 1px solid #707070;
  border-radius: 12px;
  min-width: 75px;
  height: 46px;
  color: #707070;
}

hr {
  margin: 8px 0;
  border: 1px solid #707070
}

.header {
  color:#263242;
  font-size: 22px;
  font-weight: 500
}

.key-text {
  color: #000000;
  font-size: 18px;
  font-weight: 500
}

.mt-10 {
  margin-top: 10px;
}

.d-flex {
  display: flex !important;;
}

.flex-auto {
  flex: auto !important;
  width: 75% !important;
}

.mt-20 {
  margin-top: 20px;
}

#job-detail-text {
  border: none !important;;
  pointer-events: none !important;
  padding: 0 !important;
}
